@import '../../../styles/_settings.scss';

.summary-header {
  h1 {
    color: $COLOR_TITLE;
    margin: 2rem 0 0 0;

  }

  .subtitle {
    font-size: 1rem;
  }
}
