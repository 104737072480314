@import '../../../styles/_settings.scss';

.entity-selection-header {
  margin: 2rem 0 1rem 0;

  h1 {
    color: #333333;
    font-size: 1.5rem;
  }

  h2{
    font-size: 1rem;
    color: #6A6F97;
  }
}
