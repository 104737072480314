@import '../../../../styles/_settings.scss';

#login-form {
  margin-top: 1rem;
  max-width: 500px;

  .ant-form-item-label {
    padding: 0;

    label {
      color: $COLOR_BLACK;
      height: 30px;
      font-size: 1rem;
      font-weight: 600;
    }
  }
  .ant-form-item-control-input-content {

    textarea {
      min-height: 150px;
    }
  }
  .ant-input-password {
    min-width: 300px;
  }
}
