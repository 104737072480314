@import '../../../styles/_settings.scss';

.personal-info-header {

    margin-top: 2rem;

  h1 {
    color: $COLOR_TITLE;
    margin-bottom: 0px;
  }
  h2{
    font-size: 1rem;
    color: #6A6F97;
  }
}
