@import '../../../styles/_settings.scss';

.sign-header {
  h1 {
    color: $COLOR_TITLE;
    margin-top: 2rem;
  }

  h2{
    font-size: 1rem;
    color: #606A76;
  }

  .tooltip-info {
    color: $COLOR_TOOLTIP;
    cursor: pointer;
    margin-left: 0.5rem;
  }
}

.sign-ctn {
  background-color: #F9F9FF;
  border: 1px solid #E6E6F7;
  border-radius: 0.5rem;
  padding: 2rem;
  margin: 1em 0;
}

.signing-process-text {
  font-size: 1.2rem;
  color: #606A76;
}

.thanks{
  font-size: 1.5rem;
  color: $COLOR_BLACK;
  font-weight: 600;
}

.subtitle-text{
  font-size: 1rem;
  color: #6A6F97;
}

.info-text{
  font-size: 1rem;
  padding: 1rem;
  background-color: #F9F9FF;
  color: #606A76;
  border-radius: 00.5rem;
}

#download-mandate {
  cursor: pointer;
  font-size: 1rem;
  color: #ffffff;
  background-color: $COLOR_STEPS;
  padding: 0.5rem 1rem;
  border-radius: 00.5rem;
  margin: 1rem 0;
  display: block;
  width: fit-content;

  .ant-spin-nested-loading {
    width: fit-content;
  }
}
