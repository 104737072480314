@import "../../styles/_settings.scss";

#landing {
  .landing-header {
    margin: 2rem 0;

    @media screen and (min-width: $break-xxl) {
      width: 80%;
      margin: 4rem auto;
    }

    .image > img {
      width: $WIDTH_LOGO_HEADER;
    }

    .menu {
      align-self: center;
      display: flex;
      justify-content: flex-end;
    }
  }

  .landing-ctn-info {

    @media screen and (min-width: $break-xxl) {
      margin-top: 100px;
      width: 80%;
      margin: auto;
    }
    
    .title-h1 {
      margin-bottom: 2rem;
      font-weight: 600;
    }

    .title-h3 {
      color: #4D5279;
      margin-bottom: 1rem;
      font-size: 1.3rem;
      font-weight: 600;
    }

    .title-h4 {
      color: #4D5279;
      margin-bottom: 1rem;
      font-size: 1.1rem;
      font-weight: 600;
    }

    .paragraph {
      font-size: 1rem;
      font-weight: 300;
      margin-bottom: 2rem;
      color: #606a76;
    }

    span {
      font-size: 1rem;
    }

    .landing-step-img {
      margin-top: 1rem;
      width: 100%;

      @media screen and (max-width: $break-sm) {
        display: none;
      }

      @media screen and (min-width: $break-xxl) {
        width: 80%;
        margin: auto;
      }
    }

    .landing-step-img-mobile {
      width: 70%;
      display: flex;
      margin: auto;
      @media screen and (min-width: $break-md) {
        display: none;
      }
    }

    .card-ctn {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      flex-flow: column;

      .landing-card {
        width: 100%;
        text-align: center;
        border-radius: 8px;
        box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);;

        .card-text-h3 {
          font-weight: 600;
          text-align: center;
          margin: 1rem 0 3rem 0;
          color: #4D5279;
        }

        .ctn-header-banner {
          margin: 3rem 0;
        }

        .buttonStart{
          background-color: $COLOR_PRIMARY;
          border: none;
          border-radius: 0.8rem;
        }


        .availableCredits{
          margin: 2rem 0 1rem 0;
        }
      
        .moneyCheck {
          padding-left: 1rem;
          margin: 1rem 0;
          font-size: 0.8rem;
        }
      }

      .card-subtitule-p {
        margin: 0.9rem auto;
        color: #4d8dff;
        font-size: 1rem;
      }
    }
  }

  .advantages-outer {
    background-color: #F9F9FF;
    margin: 80px 0;
    text-align: center;
    padding: 20px 16px 50px;

    .text-h2 {
      font-size: 1.6rem;
      font-weight: 600;
      margin: 3rem 0;
      color: #4D5279;
    }

    .advantages-outer-message {
      display: flex;
      justify-content: center;

      span {
        font-size: 1rem;
        margin: 0 20px;

        #money-check-img {
          height: 24px;
          vertical-align: middle;
        }
      }

      #home-img {
        height: 24px;
        vertical-align: middle;
      }
    }

    .title-h4 {
      margin-top: 4rem;
      color: #6A6F97;
      font-size: 1.2rem;
    }

    .account,
    .loan,
    .creditCard {
      margin: 1rem 0 1rem 0;
      font-size: 1rem;
      color: #606a7661;

      #money-check-img,
      #piggy-bank-img,
      #home-account-img {
        height: 24px;
        opacity: 0.8;
      }
    }
  }

  .form-landing {
    justify-content: center;
    text-align: center;
    padding-bottom: 4rem;

    .text-h2 {
      font-size: 1.6rem;
      margin: 3rem 0;
      color: #707070;

      span {
        color: #0078c4;
      }
    }
  }
}

// .sign-documents-ctn {
//   margin: 0 auto;
//   border: 1px solid #917f7f21;
//   border-radius: 10px;
//   padding: 1rem;
//   margin-top: 2rem;

//   .checkbox-btn {
//     margin: 2rem 0;
//   }

//   .ctn-btn {
//     margin-bottom: 3rem;
//     text-align: center;
//   }

//   .sign-documents-img {
//     > img {
//       width: 100%;
//     }
//   }
// }

// * {
//   margin: 0px;
//   padding: 0px;
//   font-family: 'Open Sans', sans-serif;
// }

// html {
//   overflow-x: hidden;
// }

// body {
//   background: url('../../assets/img/landing/background-kretix@2x.png') no-repeat center;
//   height: 100vh;
// }

// #app-logo {
//   width: 170px;
//   float: left;
// }

// .advantages-outer {
//   background-color: #ecf4f8;
// }

// #step-img {
//   width: 80%;
// }

// #header-banner-img {
//   display: block;
// }

// #money-check-img {
//   width: 5%;
// }

// #home-img {
//   width: 5%;
// }

// .account {
//   text-align: right;
// }

// .loan {
//   text-align: center;
// }

// p.moneyCheck {
//   font-size: 15px !important;
// }

// h1 {
//   font-size: 28px;
//   color: #4d8dff !important;
// }

// h2 {
//   font-size: 28px;
//   color: #4d8dff !important;
// }

// h3 {
//   font-size: 24px;
//   color: #4d8dff !important;
// }

// h4 {
//   font-size: 24px;
//   color: #4d8dff !important;
// }

// p {
//   font-size: 15px !important;
// }
