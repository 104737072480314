@import '../../../styles/_settings.scss';

.product-selection-header {
  h1 {
    color: $COLOR_TITLE;
    margin: 2rem 0 0 0;
  }

  .subtitle {
    font-size: 1rem;
    color: #6A6F97;
  }
}

.ctn-product-selection {
  margin-top: 1rem;
}
