@import '../../../../styles/_settings.scss';

.list-item {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border-radius: 0.3rem;
  border: 1px solid $COLOR_BORDER_MESSAGE;
  padding: 1rem;
  margin-bottom: 1rem;
  color: $COLOR_BLACK;

  &.active {
    background-color: $COLOR_BACKGROUND_MESSAGE;
  }

  span.anticon.anticon-check-circle {
    color: $COLOR_SECONDARY;
    float: right;
    margin-top: 3px;
    font-size: 18px;
  }
}
