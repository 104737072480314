@import '../../../../styles/_settings.scss';

.product-selection-new-header {
  padding: 1rem 0 0 0;

  h3 {
    font-size: 0.9rem;
    margin-bottom: 1.5rem;
  }

  .new-title {
    color: $COLOR_BLACK;
    margin: 1.5rem 0 0 0;

    > span {
      font-size: 1rem;
    }
  }

  .ant-checkbox-inner {
    border-radius: 1rem;
    width: 24px;
    height: 24px;

    &::after {
      margin-left: 3px;
    }

    &::hover {
      border-radius: 1rem;
    }
  }

  .checkbox-add-product {
    margin-left: 5px;

    .ant-checkbox-checked > .ant-checkbox-inner {
      background-color: $COLOR_SECONDARY;
      border-color: $COLOR_SECONDARY;
    }
  }
}

.product-selection-new-ctn {
  .ctn {
    
    .item {
      color: $COLOR_BLACK;
    }

    .title {
      color: $COLOR_BLACK;
      font-size: 1rem;
      font-weight: 600px;
      margin: 1rem 0 0.5rem 0;

      .bank-icon {
        height: $WIDTH_LOGO;
        margin-left: 0.5rem;
      }
    }

    .item {
      cursor: pointer;
      border-radius: 0.3rem;
      border: 1px solid $COLOR_BORDER_MESSAGE;
      padding: 0.8rem 1rem;
      margin-bottom: 1rem;

      &.active {
        background-color: $COLOR_BACKGROUND_MESSAGE;
      }

      span {
        color: $COLOR_SECONDARY;
        float: right;
        margin-top: 3px;
        font-size: 18px;
      }
    }
  }
}
