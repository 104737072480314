@import '../../../../styles/_settings.scss';

div.entity-selection-item {
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
    background-color: #F9F9FF;

  }

  &.active {
    border-color: $COLOR_PRIMARY;
  }

  border-radius: 0.5rem;
  height: 100%;

  .ant-card-body {
    text-align: center;
    padding: 1.5rem 0;
    color: #333333;

    .icon-ctn {
      position: absolute;
      top: 5px;
      right: 5px;

      span {
        font-size: 1rem;

        &.active {
          color: $COLOR_PRIMARY;
        }
      }
    }
  }
}
