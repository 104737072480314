@import '../../../styles/_settings.scss';

.login-header {
  margin: 2rem 0 0 0;

  h1 {
    color: $COLOR_TITLE;
    margin-bottom: 0;
  }

  .subtitle {
    font-size: 1rem;
    color: #6A6F97;
  }

  .bank-ctn {
    font-size: 1.2rem;
    color: $COLOR_BLACK;

    .bank-icon {
      margin-right: 5px;
      height: 2.5rem;
    }
  }
}

.login-ctn {
  padding: 1rem 0 2rem 0;

  .credentials-help {
    color: #009dd6;
    text-decoration: none;
    cursor: pointer;
  }
}
