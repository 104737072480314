@import '../../../../styles/_settings.scss';

.product-selection-list {
  padding: 1rem 0 0 0;

  .list-title {
    color: $COLOR_BLACK;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 1rem;

    .bank-icon {
      height: 2rem;
      margin-right: 0.5rem;
    }
  }
}

.no-products-ctn {
  background-color: $COLOR_BACKGROUND_MESSAGE;
  border: 1px solid $COLOR_BORDER_MESSAGE;
  border-radius: 5px;
  padding: 2rem;
  margin: 1em 0;

    &.active {
      background-color: $COLOR_BACKGROUND_MESSAGE;
    }

    span.anticon.anticon-check-circle {
      color: $COLOR_SECONDARY;
      float: right;
      margin-top: 3px;
      font-size: 18px;
    }
  .no-products-text {
    font-size: 1.2rem;
    color: #606A76;
  }
}

